// This is used by both the Alerts and the GeoFences code
export type AlertAction = {
    id?: number;
    nameId?: number;
    typeId: ActionType;
    alertId?: number;
    emailAddresses?: string[];
    emailSubject?: string;
    emailBody?: string;
    phoneNumbers?: string[];
    textMessage?: string;
    onEnter?: boolean;
    onExit?: boolean;
};

export enum ActionType {
    Text = 1,
    Email = 2,
    Log = 3,
    MQTT = 4,
}

export const whenSelectOptions = [
    { value: 0, name: 'alert.action.when.enter' },
    { value: 1, name: 'alert.action.when.leave' },
    { value: 2, name: 'alert.action.when.both' },
];

export const daysOfWeek = [
    { value: 0, name: 'sunday' },
    { value: 1, name: 'monday' },
    { value: 2, name: 'tuesday' },
    { value: 3, name: 'wednesday' },
    { value: 4, name: 'thursday' },
    { value: 5, name: 'friday' },
    { value: 6, name: 'saturday' },
];

export function processWhen(whenValue: number): {
    onEnter?: boolean;
    onExit?: boolean;
} {
    switch (whenValue) {
        case 0:
            return { onEnter: true, onExit: false };
        case 1:
            return { onEnter: false, onExit: true };
        case 2:
            return { onEnter: true, onExit: true };
        default:
            return {};
    }
}
