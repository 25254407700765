<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div class="mat-typography" mat-dialog-content>
    <form [formGroup]="form" class="flex flex-col gap-1">
        <mat-form-field class="chip-list">
            <mat-label>{{ 'alert.action.text.numbers' | translate }}</mat-label>
            <mat-chip-grid #chipGrid>
                <mat-chip-row
                    *ngFor="let phoneNumber of phoneNumbers"
                    (removed)="remove(phoneNumber)"
                >
                    {{ phoneNumber }}
                    <button matChipRemove>
                        <mat-icon svgIcon="close"></mat-icon>
                    </button>
                </mat-chip-row>
                <input
                    formControlName="text"
                    type="phone"
                    [matChipInputFor]="chipGrid"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="add($event)"
                />
            </mat-chip-grid>
        </mat-form-field>
        <div class="mat-subtitle-2">
            {{ 'alert.action.text.message' | translate }}
        </div>
        <sz-input
            #textMessage
            formControlName="textMessage"
            maxlength="1600"
            required
            [showError]="
                form.controls['textMessage'].touched &&
                form.controls['textMessage'].invalid &&
                form.controls['textMessage'].errors.required
            "
            errorMessage="form.text_message_required"
        />
        <mat-hint align="end">{{ textMessage.value.length }} / 1600</mat-hint>
        <div *ngIf="data.isGeoFence">
            <sz-select [formControl]="when" [options]="whenOptions"></sz-select>
        </div>
    </form>
    <div class="text-xs">
        {{ 'alert.interpolation_msg' | translate }}
        <br />
        <a
            class="text-primary-500"
            [routerLink]="['../../help/user-docs']"
            (click)="dialogRef.close()"
            >{{ 'help.user_documentation' | translate }}</a
        >
    </div>
    <sz-dialog-button-bar
        [primaryDisabled]="
            form.invalid ||
            phoneNumbers.length === 0 ||
            (isGeoFence && !when.valid)
        "
        (primaryClick)="confirm()"
        (secondaryClick)="dialogRef.close()"
    />
</div>
