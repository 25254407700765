<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div class="mat-typography" mat-dialog-content>
    <form [formGroup]="form" class="flex flex-col gap-1">
        <mat-form-field>
            <mat-label>{{
                'alert.action.email.addresses' | translate
            }}</mat-label>
            <mat-chip-grid #chipGrid>
                <mat-chip-row
                    *ngFor="let email of emailAddresses"
                    (removed)="remove(email)"
                >
                    {{ email }}
                    <button matChipRemove>
                        <mat-icon svgIcon="close"></mat-icon>
                    </button>
                </mat-chip-row>
                <input
                    [formControl]="email"
                    type="email"
                    [matChipInputFor]="chipGrid"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="add($event)"
                />
            </mat-chip-grid>
        </mat-form-field>
        <div class="mat-subtitle-2">
            {{ 'alert.action.email.subject' | translate }}
        </div>
        <sz-input
            [showError]="
                form.controls['emailSubject'].touched &&
                form.controls['emailSubject'].invalid &&
                form.controls['emailSubject'].errors.required
            "
            errorMessage="form.email_subject_required"
            formControlName="emailSubject"
            required
        />
        <div class="w-full">
            <div class="mat-subtitle-2">
                {{ 'alert.action.email.body' | translate }}
            </div>
            <sz-text-box
                placeholder="alert.action.email.body"
                [formControl]="form.controls['emailBody']"
            />
            <mat-error
                *ngIf="
                    form.controls['emailBody'].touched &&
                    form.controls['emailBody'].invalid &&
                    form.controls['emailBody'].errors.required
                "
                >{{ 'form.email_body_required' | translate }}
            </mat-error>
        </div>
        <div *ngIf="data.isGeoFence">
            <sz-select [formControl]="when" [options]="whenOptions"></sz-select>
        </div>
    </form>
    <div class="text-xs">
        {{ 'alert.interpolation_msg' | translate }}
        <br />
        <a
            class="text-primary-500"
            [routerLink]="['../../help/user-docs']"
            (click)="dialogRef.close()"
            >{{ 'help.user_documentation' | translate }}</a
        >
    </div>
    <sz-dialog-button-bar
        [primaryDisabled]="
            form.invalid ||
            emailAddresses.length === 0 ||
            (isGeoFence && !when.valid)
        "
        (primaryClick)="confirm()"
        (secondaryClick)="dialogRef.close()"
    />
</div>
