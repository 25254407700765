import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectOption } from 'src/app/settings/settings.model';
import { clone } from 'src/app/shared/common';
import { CustomValidator } from 'src/app/shared/custom.validators';
import {
    processWhen,
    whenSelectOptions,
} from 'src/app/shared/models/alert-action';

@Component({
    templateUrl: './alert-email-dialog.component.html',
})
export class AlertEmailDialog implements OnInit {
    emailAddresses: string[] = [];
    whenOptions: SelectOption[] = whenSelectOptions;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    when = new FormControl(null);
    email = new FormControl('', CustomValidator.email);
    form = new FormGroup({
        emailBody: new FormControl('', Validators.required),
        emailSubject: new FormControl('', Validators.required),
        onEnter: new FormControl(null),
        onExit: new FormControl(null),
    });

    get isGeoFence() {
        return this.data?.isGeoFence;
    }

    constructor(
        public dialogRef: MatDialogRef<AlertEmailDialog>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {}

    ngOnInit() {
        this.form.patchValue(this.data?.alert);
        this.emailAddresses = clone(this.data?.alert?.emailAddresses) ?? [];
        this.when.patchValue(this.data?.when);
        if (this.isGeoFence) {
            this.when.setValidators(Validators.required);
        }
    }

    confirm() {
        const when = processWhen(this.when?.value);
        this.dialogRef.close({
            ...this.form.value,
            emailAddresses: this.emailAddresses,
            ...when,
        });
    }

    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value && this.email.valid) {
            this.emailAddresses.push(value);
            event.chipInput?.clear();
        }
    }

    remove(email: string): void {
        const index = this.emailAddresses.indexOf(email);
        if (index >= 0) this.emailAddresses.splice(index, 1);
    }
}
